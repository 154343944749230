/* .nav-link.active {
    background-color: #624BFF !important;
    color: white !important;
    border-bottom: 3px solid #624BFF !important;
  }
  
  .nav-link {
    color: black !important;
    background-color: white;
  } */

/* HARSH */

.data-row {
  transition: background-color 0.2s ease;
}

.data-row:hover {
  background-color: #f7f7f7;
  /* Highlight hovered row */
}

.row-selected {
  background-color: #f1f1f1 !important;
  border-left: 4px solid #A5596A;
}

.sub-heading span {
  height: 8px;
  width: 8px;
  background-color: #212b36;
  /* Replace #333333 with your actual color value */
  display: inline-block;
}

.sub-heading p {
  font-size: 17px;
  color: #212b36;
  /* Replace #333333 with your actual color value */
  font-weight: 600;
}

.customer-de-card {
  gap: 12px;
}

.customer-de-card .re-title {
  gap: 6px;
}

.customer-de-card .re-title p {
  color: #212b36;
  /* Replace with the actual color value of $gray-800 */
  font-weight: 600;
}

.customer-de-card p {
  line-height: 16px;
}

.navLink {
  color: black !important;
  background-color: white;
}

.navLinkActive {
  background-color: #624bff !important;
  color: white !important;
  border-bottom: 3px solid #624bff !important;
}

.tablecontainer {
  /* margin: 20px;
  padding: 30px; */
  background: white;
  border-radius: 8px;
}

.searchbar {
  background: #f1f5f9;
  border: none;
  padding: 7px;
  border-radius: 5px;
}

.input_btn {
  margin-bottom: 70px;
}

.data_list {
  overflow-x: auto;
}

.data_list thead th:first-child {
  border-radius: 10px 0 0;
}

.data_list thead th:last-child {
  border-radius: 0 10px 0 0;
}

.data_list thead th {
  color: #454f5b;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.data_list tbody td {
  color: #454f5b;
  font-size: 15px;
  line-height: 18px;
}

.list_pagination li.prev_btn a,
.list_pagination li.next_btn a {
  border: none;
  color: #919eab;
  background-color: transparent;
}

.list_pagination li.prev_btn,
.list_pagination li.next_btn {
  margin: 5px 0 !important;
}

.list_pagination li {
  margin: 5px;
}

.list_pagination .active a {
  background: #637381;
  border: none;
  color: white;
  border-radius: 8px;
}

.list_pagination li a {
  background-color: #eff1f2;
  border-radius: 8px;
  border: none;
  color: black;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}

li.next_btn.page-item.disabled span {
  border: none;
  background: transparent;
}

li.prev_btn.page-item.disabled span {
  border: none;
  background: transparent;
}

.data_list tbody .data_row:hover td {
  background: #f4f6f8;
}

.list_pagination {
  padding-top: 80px;
}

.bg-lightgray .modal-content {
  background-color: #f1f5f9;
  padding: 40px;
}

.customer_data .input_head {
  margin-bottom: 30px;
}

.download-pdf-btn{
  border:none;
  background:transparent;
}

.customer_data .input_head input {
  outline: none;
}

.customer_data .input_head h5 {
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  color: #212b36;
}

.tablist_data li button {
  border: none !important;
}

.tablist_data li button.active {
  background-color: #f6f5ff !important;
  color: #624bff !important;
  border-radius: 8px !important;
}

.tablist_data li button {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #212b36 !important;
  padding: 12px 24px;
}

.customer_details {
  padding: 30px;
  background: white;
  border-radius: 8px;
}

.detail_person .user_name {
  width: 150px;
  font-size: 14px;
  color: #637381;
}

.detail_person .part_name {
  width: 20px;
  font-size: 16px;
  color: #454f5b;
}

.detail_person .detail_name {
  font-size: 16px;
  color: #454f5b;
}

.separtor.line {
  height: 2px;
  width: 100%;
  background: #e6e6e6;
}

.customer_details h5 {
  font-size: 22px;
  font-weight: 500;
}

.modal-fullscreen .modal-title {
  font-size: 28px;
  color: #624bff;
}

.modal-fullscreen .modal-header button {
  opacity: 0.7;
}

.box-color {
  background-color: var(--purple-two);
}

/* campaigns detail pdf & image preview zoom in ,out */
.zoom-container {
  position: relative;
  cursor: pointer;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  max-width: 100%;
  max-height: 100%;
  /* overflow: hidden; */
  display: inline-block;
}

.zoom-container img,
.zoom-container .document-wrapper {
  max-width: 100%;
  max-height: 100%;
}

.zoom-container.zoomed {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2);
  z-index: 1050;
  padding: 20px;
  border-radius: 8px;
}

.zoom-container.zoomed img,
.zoom-container.zoomed .document-wrapper {
  max-width: 90vw;
  max-height: 90vh;
}

.page-content,
.image-content {
  display: block;
  width: 223%;
  height: 200%;
}

.max-width-100 {
  max-width: 100%;
}

.close-button:hover {
  color: #635b5b;
  /* Optional: Change color on hover */
}

.top-right {
  top: 16px;
  right: -65px;
}

.h-107 {
  height: 107% !important;
}

.margin-top-4 {
  margin-top: 3px;
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8); /* Dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.gallery-container {
  width: 80%;
  max-width: 900px;
  background: transparent; /* No white background */
}


.pdf-modal-outer-div {
  max-width: 466px;
  max-height: 69vh;
  /* max-width: 100%; */
  /* max-height: 145vh; */
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: white;
}

.pdf-modal-inner-div {
  width: 100%;
  height: 117vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .swiper-numbers{
  color: var(--black-one) !important;
  font-weight: var(--font-semibold);
  /* max-width: 60px !important; */
/* margin: 16px auto 0 auto;
  position: static;
  text-align: center;
} */

.cursor {
  cursor: pointer
}

.pdf-modal-prev-btn {
  position: absolute;
  left: 6px !important;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: transparent;
  border: none;
  cursor: pointer;
  color:#7367f0 !important;
}

.pdf-modal-nxt-btn {
  position: absolute;
  right: 8px !important;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: transparent;
  border: none;
  cursor: pointer;
  color:#7367f0 !important;
}

/* Class for givng Minimum height to tables */
/* .custom-style-2 {
  height: 91vh;
  min-height: 91vh;
  overflow-y: scroll !important;
} */

/* Class for givng Minimum height to tables */
/* .custom-style-3 {
  height: 90vh;
  min-height: 90vh;
  overflow: auto;
} */




/* Container for the custom table */
.custom-table-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  max-width: 100%;
  overflow-x: auto;
}

/* Header styles */
.custom-table-header {
  background-color: #f8f9fa;
  border-bottom: 2px solid #ddd;
  font-weight: bold;
  display: flex;
  flex: 1;
  min-width: fit-content;
  /* Ensures all columns fit content */
}

.custom-table-row {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.2s ease;
  flex: 1;
  min-width: fit-content;
  /* Ensures all columns fit content */
}

.custom-table-row:hover {
  background-color: #f1f1f1;
}

/* .row-selected {
  background-color: #f1f1f1;
} */

/* Cell styles */
.custom-table-cell {
  flex: 1;
  padding: 10px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom-table-cell:first-child {
  flex: 0 0 40px;
  /* Fixed width for the checkbox column */
}

.custom-table-cell:last-child {
  flex: 0 0 100px;
  /* Fixed width for the actions column */
  text-align: center;
}

/* Blank table view styles */
.blank-table-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #6c757d;
  text-align: center;
}

.blank-table-view img {
  max-width: 250px;
  margin-bottom: 24px;
}

.blank-table-view h5 {
  font-size: 18px;
  margin-bottom: 10px;
}

.blank-table-view p {
  font-size: 14px;
  margin: 0;
}

/* Action menu styles */
.action-menu {
  position: relative;
}

.action-menu button {
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.action-menu button:hover {
  color: #007bff;
}

/* Responsive adjustments */

@media screen and (max-width:1920px) {
  .blank-table-view img {
    max-width: 150px;
    margin-bottom: 15px;
  }
}



@media screen and (max-width: 768px) {
  .custom-table-cell {
    font-size: 14px;
    padding: 8px;
  }

  .custom-table-cell:last-child {
    flex: 0 0 80px;
  }
}